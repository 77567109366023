<template>
  <b-container fluid>
    <div v-if="!loading">
      <b-card
        class="p-50"
      >
        <b-card-title class="mb-0">
          <h4 class="text-uppercase">
            Rates
          </h4>
        </b-card-title>

        <b-card-body class="px-2 py-0">
          <b-row v-if="Object.keys(chartData).length">
            <b-col cols="12" class="p-0">
              <b-row class="justify-content-center">
                <b-col cols="8">
                  <b-progress
                    :max="chartData.total_hits"
                    height="13px"
                  >
                    <b-progress-bar
                      variant="success"
                      :value="chartData.total_success"
                      animated
                    />
                    <b-progress-bar
                      variant="danger"
                      :value="chartData.total_error"
                      animated
                    />
                  </b-progress>
                </b-col>
              </b-row>
              <b-col cols="12" class="mt-50">
                <h6 class="text-center text-muted font-weight-bolder text-uppercase">
                  Hits {{ totalSuccess }}/{{ totalHits }} success rate {{ (( chartData.total_success / Math.max(chartData.total_hits, 1)) * 100).toFixed(2) }}%
                </h6>
              </b-col>
              <vue-apex-charts
                type="line"
                height="400"
                :options="generalRatesOptions.chartOptions"
                :series="generalRatesOptions.series"
              />
              <b-col cols="12" v-if="chartData">
                <h6 class="mb-3 text-center text-muted font-weight-bolder">From {{ formatDate(chartData.time_keys[0]) }} to {{ formatDate(chartData.time_keys.at(-1)) }} with an interval of {{ chartData.interval }}</h6>
              </b-col>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
    <b-card v-else>
      <b-card-title class="mb-0">
        <b-skeleton width="200px" height="25px"/>
      </b-card-title>
      <b-row class="justify-content-center">
        <b-col cols="8" class="mb-2">
          <b-skeleton type="input" class="rounded-pill" height="20px"/>
          <b-skeleton class="mt-50 rounded " height="20px"/>
        </b-col>
        <b-col cols="12" class="mb-0">
          <b-skeleton-img
            no-aspect
            height="380px"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import { 
  BContainer, 
  BRow, 
  BCard, 
  BCardText, 
  BCardBody,
  BCardTitle,
  BLink, 
  BButton, 
  BCol, 
  BProgress, 
  BProgressBar,
  BSkeleton,
  BSkeletonImg,
  BCollapse,
  BCardHeader,
  BTabs,
  BTab
} from 'bootstrap-vue'

import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

  export default {
    components: {
      BContainer, 
      BRow, 
      BCard, 
      BCardText, 
      BLink, 
      BButton, 
      BCol, 
      BProgress, 
      BProgressBar,
      BSkeleton,
      BSkeletonImg,
      VueApexCharts,
      BCardBody,
      BCardTitle,
      BCollapse,
      BCardHeader,
      BTabs,
      BTab,
    },
    props: {
      value: {
        type: Object,
        default: undefined
      }
    },
    data() {
      return {
        loading: true,
        content_visible: true,

      }
    },
    mounted() {
      this.$emit('created')
    },
    computed: {
      chartData: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input' , value)
        }
      },
      totalSuccess() {
        return this.chartData.total_success ? this.chartData.total_success.toLocaleString(this.$t('locale')) : 0
      },
      totalHits() {
        return this.chartData.total_hits ? this.chartData.total_hits.toLocaleString(this.$t('locale')) : 0
      },
      generalRatesOptions() {
        return {
          series: [
            {
              type: 'line',
              name: 'Total Hits',
              data: this.chartData.hit_counts
            },
            
            {
              type: 'line',
              name: 'Errors',
              data: this.chartData.error,
            },
            {
              type: 'line',
              name: 'Successes',
              data: this.chartData.success,
            },
            {
              type: 'column',
              name: "Avg. Time",
              data: this.chartData.avg_elapsed_time
            },
          ],
          chartOptions: {
            chart: {
              toolbar: {
                show: true,
                tools: {
                  download: false,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: false,
                  reset: true,
                },
              },
              fontFamily: 'Montserrat',
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              width: [5, 5, 5, 0],
              show: true,
              curve: 'smooth',
            },
            legend: {
              show: true,
              position: 'top',
              horizontalAlign: 'left',
              fontSize: '14px',
              fontFamily: 'Montserrat',
              labels: {
                colors: '#fff',
              },
            },
            grid: {
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              categories: this.chartData.time_keys,
              labels: {
                show: false,
                style: {
                  colors: '#fff',
                  fontSize: '10px',
                  fontFamily: 'Montserrat',
                  fontWeight: 900,
                  cssClass: 'apexcharts-xaxis-label',
                },
                formatter: this.formatDate
              },
            },
            yaxis: [
              {
                title:{
                  text:"Hits",
                  style: {
                    fontSize:  '10px',
                    fontWeight:  'bold',
                    fontFamily:  'Montserrat',
                    color:  '#fff'
                  },
                },
                min: 0,
                max: this.maxElapsedTime(this.chartData.hit_counts),
                tickAmount: this.maxTickAmount(this.chartData.hit_counts),
                labels: {
                  style: {
                    colors: '#fff',
                    fontSize: '10px',
                    fontFamily: 'Montserrat',
                    fontWeight: 900,
                    cssClass: 'apexcharts-xaxis-label',
                  },
                  formatter: (value) => {
                    return value.toFixed(0)
                  }
                },
              },
              {
                min: 0,
                max: this.maxElapsedTime(this.chartData.hit_counts),
                tickAmount: this.maxTickAmount(this.chartData.hit_counts),
                labels: {
                  show: false,
                  formatter: (value) => {
                    return value.toFixed(0)
                  }
                },
              },
              {
                min: 0,
                max: this.maxElapsedTime(this.chartData.hit_counts),
                tickAmount: this.maxTickAmount(this.chartData.hit_counts),
                labels: {
                  show: false,
                  formatter: (value) => {
                    return value.toFixed(0)
                  }
                },
              },
              {
                title:{
                  text:"Elapsed Time",
                  style: {
                    fontSize:  '10px',
                    fontWeight:  'bold',
                    fontFamily:  'Montserrat',
                    color:  '#fff'
                  },
                },
                opposite: true,
                min: 0,
                max: this.maxElapsedTime(this.chartData.avg_elapsed_time),
                tickAmount: this.maxTickAmount(this.chartData.avg_elapsed_time),
                labels: {
                  style: {
                    colors: '#fff',
                    fontSize: '10px',
                    fontFamily: 'Montserrat',
                    fontWeight: 900,
                    cssClass: 'apexcharts-xaxis-label',
                  },
                  formatter: (value) => {
                    return (Math.max(value / (1000 * 1000), 0)).toFixed(4) + 's'
                  }
                },
              },

            ],
            // fill: {
            //   opacity: 1,
            //   type: 'solid',
            // },
            tooltip: {
              shared: true,
              theme: 'dark'
            },
            colors: ['#fff', '#ea5455', '#12e066', '#00cfe880'],
            markers: {
              size: 5,
              strokeWidth: 0,
              fillOpacity: 1,
              discrete: [],
              shape: "circle",
              radius: 2,
              showNullDataPoints: false,
            },
            plotOptions: {
              bar: {
                borderRadius: 5,
                columnWidth: '25%',
                colors: {
                  ranges: [{
                    from: 0,
                    to: Math.max(...this.chartData.avg_elapsed_time),
                    color: '#00cfe880'
                  }],
                  backgroundBarColors: [],
                  backgroundBarOpacity: 1,
                  backgroundBarRadius: 0,
                },
              }
            }
          },
        }
      },
      
    },
    methods: {
      maxElapsedTime(value) {
        return Math.max(Math.max(...value) * 1.2, 1)
      },
      maxTickAmount(value) {
        return Math.min(Math.max(Math.max(...value) * 1.2, 1), 15)
      },
      formatDate(value) {
        if (!value) return null
        let date = new Date(value)
        return moment(date).format(this.$t('scheduler.view.moment_date_format'));
      },
      getStartingDate() {
        return this.chartData.total_success.toLocaleString(this.$t('locale'))
      },
      getEndingDate() {

      }
    }
  }
</script>

<style lang="scss" scoped>

</style>