<template>
  <div>
    <div>
      <b-container fluid>
        <b-collapse
          v-model="advancedFilters"
        >
          <b-row>
            <b-col md="4">
              <b-form-group
                label-for="origin-type"
              >
                <template #label>
                  <helper-tooltip size="15" innerText=""/>
                  <span class="small">
                    Filter by Origin
                  </span>
                </template>
                <v-select
                  id="origin-type"
                  taggable
                  multiple
                  v-model="origin_filter.origin_type"
                  :options="originTypes"
                  :reduce="(option) => option.filter || option"
                  :getOptionLabel="(option) => option.filter || option"
                  selectOnTab
                  :select-on-key-codes="[188, 108, 13]"
                  :clearSearchOnBlur="() => true"
                  @input="popOrigin('origin_type')"
                  @search="(value) => { setOriginHolder(value, 'origin_type', 'filter')}"
                  @search:blur="pushOriginHolder('origin_type', 'filter')"
                >
                  <template slot="no-options">
                    {{ $t('common.terms.no_options') }}
                  </template>
                  <template v-slot:option="option">
                    {{ option.label }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label-for="origin-service"
              >
                <template #label>
                  <span class="small">
                    By Service
                  </span>
                </template>
                <v-select
                  id="origin-service"
                  taggable
                  multiple
                  v-model="origin_filter.origin_service"
                  :options="origin_service_options"
                  :getOptionLabel="(option) => option.identifier || option"
                  :reduce="(option) => option.identifier || option"
                  selectOnTab
                  :select-on-key-codes="[188, 108, 13]"
                  :clearSearchOnBlur="() => true"
                  @input="popOrigin('origin_service')"
                  @search="(value) => { setOriginHolder(value, 'origin_service', 'identifier')}"
                  @search:blur="pushOriginHolder('origin_service', 'identifier')"
                >
                  <template slot="no-options">
                    {{ $t('common.terms.no_options') }}
                  </template>
                  <template v-slot:option="option">
                    {{ option.identifier }}
                  </template>
              </v-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label-for="origin-event"
              >
                <template #label>
                  <span class="small">
                    By Event
                  </span>
                </template>
                <v-select
                  id="origin-event"
                  taggable
                  multiple
                  v-model="origin_filter.origin_event"
                  :options="origin_event_options"
                  :getOptionLabel="(option) => option.event_identifier || option"
                  :reduce="(option) => option.event_identifier || option"
                  selectOnTab
                  :select-on-key-codes="[188, 108, 13]"
                  :clearSearchOnBlur="() => true"
                  @input="popOrigin('origin_event')"
                  @search="(value) => { setOriginHolder(value, 'origin_event', 'event_identifier')}"
                  @search:blur="pushOriginHolder('origin_event', 'event_identifier')"
                >
                  <template slot="no-options">
                    {{ $t('common.terms.no_options') }}
                  </template>
                  <template v-slot:option="option">
                    {{ option.event_identifier }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label-for="origin-webhook"
              >
                <template #label>
                  <span class="small">
                    By webhook
                  </span>
                </template>
                <v-select
                  id="origin-webhook"
                  taggable
                  multiple
                  v-model="origin_filter.origin_webhook"
                  :options="origin_webhook_options"
                  :getOptionLabel="(option) => option.identifier || option"
                  :reduce="(option) => option.identifier || option"
                  selectOnTab
                  :select-on-key-codes="[188, 108, 13]"
                  :clearSearchOnBlur="() => true"
                  @input="popOrigin('origin_webhook')"
                  @search="(value) => { setOriginHolder(value, 'origin_webhook', 'identifier')}"
                  @search:blur="pushOriginHolder('origin_webhook', 'identifier')"
                >
                  <template slot="no-options">
                    {{ $t('common.terms.no_options') }}
                  </template>
                  <template v-slot:option="option">
                    {{ option.identifier }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label-for="origin-scheduler"
              >
                <template #label>
                  <span class="small">
                    By scheduler
                  </span>
                </template>
                <v-select
                  id="origin-scheduler"
                  taggable
                  multiple
                  v-model="origin_filter.origin_scheduler"
                  :options="origin_scheduler_options"
                  :getOptionLabel="(option) => option.name || option"
                  :reduce="(option) => option.name || option"
                  selectOnTab
                  :select-on-key-codes="[188, 108, 13]"
                  :clearSearchOnBlur="() => true"
                  @input="popOrigin('origin_scheduler')"
                  @search="(value) => { setOriginHolder(value, 'origin_scheduler', 'name')}"
                  @search:blur="pushOriginHolder('origin_scheduler', 'name')"
                >
                  <template slot="no-options">
                    {{ $t('common.terms.no_options') }}
                  </template>
                  <template v-slot:option="option">
                    {{ option.name }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label-for="origin-token"
              >
                <template #label>
                  <helper-tooltip size="15" innerText=""/>
                  <span class="small">
                    By Token
                  </span>
                </template>
                <v-select
                  id="origin-token"
                  taggable
                  multiple
                  v-model.trim="origin_filter.token"
                  noDrop
                  selectOnTab
                  :select-on-key-codes="[188, 108, 13]"
                  :clearSearchOnBlur="() => true"
                  @input="popOrigin('token', true)"
                  @search="(value) => { setOriginHolder(value, 'token', null)}"
                  @search:blur="pushOriginHolder('token', null)"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <div class="mb-50">
                <span class="small text-light-gray">
                  Show results
                </span>
              </div>
              <div>
                <b-form-radio 
                  class="custom-control-success d-inline"
                  value="true"
                  v-model="success_option"
                >
                  Only success
                </b-form-radio>
                <b-form-radio 
                  class="custom-control-danger d-inline mx-md-1"
                  value="false"
                  v-model="success_option"
                >
                  Only failed
                </b-form-radio>
                <b-form-radio 
                  class="custom-control-info d-inline"
                  :value="null"
                  v-model="success_option"
                >
                  Both
                </b-form-radio>
              </div>
            </b-col>
            <b-col class="text-right">
              <b-button b-button variant="outline-secondary" class="mt-1" size="sm" @click="resetItems">
                <div v-if="!loadingItems">
                  <span>Reset</span>
                </div>
                <b-spinner v-else small variant="light" />
              </b-button>
            </b-col>
          </b-row>
        </b-collapse>
        <b-row>
          <b-col cols="12" class="my-1 text-center">
            <b-form-group 
              class="d-inline-block text-left"
              label-for="from-date-picker" 
              :label="$t('common.terms.from')"
              label-class="text-uppercase"
            >
              <flat-pickr
                id="from-date-picker"
                placeholder="No date selected"
                v-model="from"
                class="form-control"
                :config="flatPickrConfig"
              />
            </b-form-group>
            <b-form-group 
              class="d-inline-block text-left ml-1"
              label-for="to-date-picker" 
              :label="$t('common.terms.to')"
              label-class="text-uppercase"
            >
              <flat-pickr
                id="to-date-pickr"
                placeholder="No date selected"
                v-model="to"
                class="form-control"
                :config="flatPickrConfig"
              />
            </b-form-group>
            <b-form-group
              class="d-inline-block text-left ml-1"
              label=""
              label-for="filter-button"
            >
              <b-button id="filter-button" class="minus-one-top" :class="loadingItems ? 'py-75 px-1' : ''" :disabled="loadingItems" variant="info" @click="refreshItems">
                <feather-icon
                  v-if="!loadingItems"
                  icon="SearchIcon"
                  class="mr-50 p-0"
                />
                <b-spinner v-else class="mr-75 ml-50" small/>
                <span>Filter</span>
              </b-button>
            </b-form-group>
            <b-form-group
              class="d-inline-block text-left float-right ml-2 mt-3"
            >
              <b-form-checkbox
                variant="succcess"
                switch
                v-model="advancedFilters"
              >
                Advanced Filters
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-col cols="12" v-if="from && from.toString().length <= 11" class="p-0">
      <transmission-charts
        ref="transmission-chart"
        v-model="chartData"
        @created="getChartsData"
      />
    </b-col>
    <div class="mb-1">
      <b-button variant="info" class="py-50 ml-1 mb-50 low-opacity" @click="showCategoryLogs = !showCategoryLogs">
        <span class="text-black font-weight-bolder">
          <template v-if="!showCategoryLogs">View Logs By Category</template>
          <template v-else>Hide Category Logs</template>
        </span>
      </b-button>
      <b-collapse :visible="showCategoryLogs">
        <b-col cols="12">
          <b-card
            class="p-50"
            no-body
          >
            <b-tabs>
              <b-tab v-for="origin in origins" :key="origin" lazy>
                <template #title>
                  {{ $t(`transmission.dashboard.origin.${origin}.tab_title`) }}
                </template>
                <b-card-body>
                  <b-row>
                    <b-col>
                      <dashboard-analytics-table
                        :ref="`analytic-table-${origin}`"
                        :key="`analytic-table-${origin}`"
                        :timeKeys="chartData.time_keys || []"
                        :origin="origin"
                        v-model="chartData[origin]"
                        @searchBy="(identifier) => setFilter(origin, identifier)"
                      >
                      </dashboard-analytics-table>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-collapse>
    </div>
    <b-col v-if="from && from.toString().length <= 11">
      <log-table
        ref="transmission-log-table"
        scrollContainer="body"
        v-model="items"
        :scroll_id="scroll_id"
        primaryColumn="origin"
        @created="getTransmissionLogs"
        @goNextPage="goNextPage"
        @setLoad="setLoadingItems"
        @refreshLogs="refreshLogs()"
      />
    </b-col>
  </div>
</template>

<script>
import { 
  BContainer, 
  BRow, 
  BCard, 
  BCardText, 
  BCardTitle, 
  BCardBody,
  BLink, 
  BButton, 
  BCol, 
  BFormGroup, 
  BFormInput,
  BIcon, 
  BSpinner, 
  BFormCheckbox, 
  BCollapse,
  BFormRadio,
  BTabs,
  BTab
  } from 'bootstrap-vue'
  
import { makeToast } from "@/layouts/components/Popups";
import TransmissionCharts from '@/layouts/components/Transmission/Dashboard/Charts.vue'
import LogTable from '@/layouts/components/Dashboard/LogTable.vue'
import VSelect from 'vue-select'
import moment from "moment";
import flatPickr from 'vue-flatpickr-component'
import OriginType from '@/custom/class/Enum/OriginType'
import DashboardAnalyticsTable from '@/layouts/components/Dashboard/AnalyticsTable.vue'

import HelperTooltip from '@/layouts/components/HelperTooltip.vue'
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
    BCol,
    TransmissionCharts,
    LogTable,
    flatPickr,
    BIcon,
    BFormGroup,
    BFormInput,
    BContainer,
    BRow,
    BSpinner,
    BFormCheckbox,
    VSelect,
    BCollapse,
    HelperTooltip,
    BFormRadio, 
    BCardTitle, 
    BCardBody,
    DashboardAnalyticsTable,
    BTabs,
    BTab
  },
  data() {
    return {
      flatPickrConfig: { enableTime: true, dateFormat: 'U', altInput: true, altFormat: 'F j, Y H:i', time_24hr: true, minuteIncrement: 1, },
      showCategoryLogs: false,
      origins: [
        "by_event",
        "by_service",
        "by_event_and_service",
        "by_origin",
        "by_webhook",
        "by_scheduler"
      ],

      content_visible: true,
      items: null,
      loadingItems: false,

      scroll_id: undefined,

      filters: {},
      advancedFilters: false,
      from: null,
      to: null,
      origin_filter: {
        origin_type: [],
        origin_scheduler: [],
        origin_service: [],
        origin_event: [],
        origin_webhook: [],
        token: [],
      },
      origin_holder: {},

      success_option: null,

      origin_webhook_options: undefined,
      origin_service_options: undefined,
      origin_event_options: undefined,
      origin_scheduler_options: undefined,
      origin_type_options: undefined,

      rawChartData: {},
      chartData: {},

      total_success: null,
      total_error: null,
      total_hits: null,

    }
  },
  computed: {
    transmissionID() {
      return this.$route.params.transmissionID
    },
    originTypes() {
      return (new OriginType).items
    }
  },  
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.setDefaultInterval()
      this.getFilterOptions()
    },
    getFilterOptions() {
      this.getServiceByTransmission()
      // this.getWebhookByTransmission()
      this.getEventsByTransmission()
      this.getSchedulerByTransmission()
    },
    refreshItems() {
      this.getTransmissionLogs()
      this.getChartsData()
    },
    setDefaultInterval() {
      this.to = Date.now();

      let last_week = Date.now() - (7 * 24 * 60 * 60 * 1000);
      this.from = last_week;
    },
    setLoadingItems(state) {
      this.loadingItems = state;
    },
    setupFilters() {
      this.filters = {};

      if (this.advancedFilters) {
        Object.keys(this.origin_filter).forEach((value) => {
          if (this.origin_filter[value].length) {
            this.filters[value] = this.origin_filter[value].join();
          }
        })
      }
      
      this.filters.from = this.from;
      this.filters.to = this.to;

      if (this.success_option != null) {
        this.filters.success = this.success_option;
      }
    },
    setFilter(origin, identifier) {
      this.resetItems()
      switch(origin) {
        case "by_webhook": {
          this.origin_filter.origin_webhook.push(identifier)
        } break;
        case "by_service": {
          this.origin_filter.origin_service.push(identifier)
        } break;
        case "by_event": {
          this.origin_filter.origin_event.push(identifier)
        } break;
        case "by_event_and_service": {
          let arr = identifier.split(',');
          this.origin_filter.origin_event.push(arr[0]);
          this.origin_filter.origin_service.push(arr[1]);
        } break;
        case "by_origin": {
          this.origin_filter.origin_type.push(identifier);
        } break;
        case "by_scheduler": {
          this.origin_filter.origin_scheduler.push(identifier);
        } break;
      }
      this.advancedFilters = true
      this.refreshItems()
    },
    refreshLogs(){
      this.setDefaultInterval()
      this.getTransmissionLogs()
      setTimeout(() => {
        this.$refs['transmission-log-table'].scrollIntoView({behavior: "smooth"})
      }, 250);
    },
    getTransmissionLogs() {
      this.setLoadingItems(true)
      this.items = undefined;
      
      this.setupFilters();

      this.$store.dispatch('getTransmissionLogs', {
        transmissionID: this.$route.params.transmissionID,
        filters: {
          ...this.filters
        }
      }).then((resp) => {
        this.scroll_id = resp._scroll_id.replace('==', '');
        this.items = resp.hits.hits;
        this.setLoadingItems(false)

        if(this.items[0] && !this.to) {
          this.to = this.items[0]._source.created_at;
        }


      }).catch((err) => {
        console.error(err);
        this.setLoadingItems(false)
      });
    },
    goNextPage() {
      if(!this.items || !this.items.length) return;
      
      this.$refs['transmission-log-table'].searchingNextPage = true;

      this.$store.dispatch('getTransmissionLogsPaginated', {
        transmission_id: this.transmissionID,
        scroll_id: this.scroll_id,
      }).then((resp) => {
        if(resp.hits.hits.length === 0) {
          this.scroll_id = undefined;
        } else {
          this.items.push(...resp.hits.hits);
        }

        this.$refs['transmission-log-table'].searchingNextPage = false;
        this.$nextTick(()=>{
          this.$refs['transmission-log-table'].sustainSelected()
        })
        
      }).catch((err) => {
        console.error(err);
        this.$refs['transmission-log-table'].searchingNextPage = false;
      });
    },
    getServiceByTransmission() {
      this.$store
        .dispatch("getServicesByTransmissionGroup", {
          transmissionID: this.transmissionID,
        })
        .then((response) => {
          this.origin_service_options = response.items || [];
        })
        .catch(() => {
          makeToast({
            title: this.$t(`default_changer.toast.submit.error.title`),
            text: this.$t(`default_changer.toast.submit.error.text`),
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    getEventsByTransmission() {
      this.$store
        .dispatch("fetchEventsByTransmissionGroup", {
          transmissionID: this.transmissionID,
        })
        .then((response) => {
          this.origin_event_options = response || [];
        })
        .catch(() => {
          makeToast({
            title: this.$t(`default_changer.toast.submit.error.title`),
            text: this.$t(`default_changer.toast.submit.error.text`),
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    getWebhookByTransmission() {
      this.$store
        .dispatch("getWebhookByTransmission", {
          transmissionID: this.transmissionID,
        })
        .then((response) => {
          this.origin_webhook_options = response.items || [];
        })
        .catch((error) => {
          makeToast({
            title: this.$t(`default_changer.toast.submit.error.title`),
            text: this.$t(`default_changer.toast.submit.error.text`),
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    getSchedulerByTransmission() {
      this.$store
        .dispatch("getSchedules", {
          transmissionID: this.transmissionID,
        })
        .then((response) => {
          this.origin_scheduler_options = response || [];
        })
        .catch((err) => {
          console.error(err)
          makeToast({
            title: this.$t(`default_changer.toast.submit.error.title`),
            text: this.$t(`default_changer.toast.submit.error.text`),
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    getChartsData() {
      this.setChildComponentsLoading(true)
      this.setLoadingItems(true)
      
      this.setupFilters();

      this.$store.dispatch('getTransmissionStats',
        {
          transmission_id: this.transmissionID,
          filters: {
            ...this.filters
          }
        }
      ).then((resp) => {
        this.rawChartData = resp
        this.setupChartData()
        this.setLoadingItems(false)

      }).catch((error) => {
        console.error(error)
        this.setChildComponentsLoading(false)
        this.setLoadingItems(false)
      })
    },
    setupChartData() {
      let rates;

      this.chartData = {
        total_hits: null,
        total_success: null,
        total_error: null,
        interval: null,

        time_keys: [],
        hit_counts: [],
        avg_elapsed_time: [],
        success: [],
        error: [],
        
        by_event: {},
        by_service: {},
        by_event_and_service: {},
        by_webhook: {},
        by_origin: {},
        by_scheduler: {},
      };

      // this.chartData.total_hits = this.rawChartData.hits.total.value
      
      if (this.rawChartData.aggregations) {
        rates = this.getRates(this.rawChartData.aggregations.success_rate.buckets)
        this.$set(this.chartData, 'total_success', rates.success)
        this.$set(this.chartData, 'total_error', rates.failure)
        this.$set(this.chartData, 'total_hits', (rates.success + rates.failure))

        this.$set(this.chartData, 'interval', this.rawChartData.aggregations.graph.interval)
        
        let graph_buckets = this.rawChartData.aggregations.graph.buckets
        this.graph_length = this.rawChartData.aggregations.graph.buckets.length
        
        graph_buckets.forEach((value) => {
          
          // General Bucket Info Setter
          this.chartData.time_keys.push(value.key)
          this.chartData.hit_counts.push(value.doc_count)
          this.chartData.avg_elapsed_time.push(value.avg_elapsed_time.value || 0)

          let outter_bucket_rate = this.getRates(value.success_rate.buckets)

          this.chartData.success.push(outter_bucket_rate.success)
          this.chartData.error.push(outter_bucket_rate.failure)
          // ----------------------- //

          // Inner Buckets Setter
          this.origins.forEach((origin) => {
            value[origin].buckets.forEach((innerBucketItem) => {
              if (!this.chartData[origin][innerBucketItem.key]) this.$set(this.chartData[origin], innerBucketItem.key, {})
              
              this.chartData[origin][innerBucketItem.key].interval = this.chartData.interval

              if (!this.chartData[origin][innerBucketItem.key]['avg_elapsed_time']) this.$set(this.chartData[origin][innerBucketItem.key], 'avg_elapsed_time', {})
              if (!this.chartData[origin][innerBucketItem.key]['hit_counts']) this.$set(this.chartData[origin][innerBucketItem.key], 'hit_counts', {})

              this.$set(this.chartData[origin][innerBucketItem.key]['avg_elapsed_time'], value.key, innerBucketItem.average_time.value)
              this.$set(this.chartData[origin][innerBucketItem.key]['hit_counts'], value.key, innerBucketItem.doc_count)

              let inner_bucket_rate = this.getRates(innerBucketItem.success_rate.buckets)

              if (!this.chartData[origin][innerBucketItem.key]['success']) this.chartData[origin][innerBucketItem.key]['success'] = []
              if (!this.chartData[origin][innerBucketItem.key]['error']) this.chartData[origin][innerBucketItem.key]['error'] = []

              this.chartData[origin][innerBucketItem.key]['success'][value.key] = inner_bucket_rate.success
              this.chartData[origin][innerBucketItem.key]['error'][value.key] = inner_bucket_rate.failure

              this.$set(this.chartData[origin][innerBucketItem.key], 'time_keys', this.chartData.time_keys)
            })
          })
        })

        this.$nextTick(() => {
          this.origins.forEach((origin) => {
            if (this.$refs[`analytic-table-${origin}`] && this.$refs[`analytic-table-${origin}`].length) {
              this.$refs[`analytic-table-${origin}`][0].init();
            }
          })
        })
      } 

      this.setChildComponentsLoading(false)
    },
    getRates(payload) {
      let failure;
      let success;

      if (payload[0]) {
        if (payload[0].key == 0) {
          failure = payload[0] ? payload[0].doc_count : 0
          success = payload[1] ? payload[1].doc_count : 0
        } else {
          failure = payload[1] ? payload[1].doc_count : 0
          success = payload[0] ? payload[0].doc_count : 0
        }
      } else {
        failure = 0
        success = 0
      }

      return { success, failure }
    },
    resetItems() {
      this.origin_filter = {
        origin_type: [],
        origin_scheduler: [],
        origin_service: [],
        origin_event: [],
        origin_webhook: [],
        token: [],
      }

      this.success_option = null
    },
    setChildComponentsLoading(state) {
      if (this.$refs['transmission-chart']){
        this.$refs['transmission-chart'].loading = state;
      }

      this.origins.forEach((origin) => {
        if (this.$refs[`analytic-table-${origin}`] && this.$refs[`analytic-table-${origin}`].length) {
          this.$refs[`analytic-table-${origin}`][0].loading = state;
        }
      })
    },
    pushOriginHolder(origin, label) {
      if (!this.origin_holder[origin]) return
      this.origin_filter[origin].push(label ? this.origin_holder[origin][label] : this.origin_holder[origin])
      
      this.clearOriginHolder(origin)
    },
    setOriginHolder(value, origin, label) {
      if (value == '' || !value) return
      this.origin_holder[origin] = label ? { [label] : value } : value
    },
    clearOriginHolder(origin) {
      this.origin_holder[origin] = null
    },
    popOrigin(origin, noOptionSelect) {

      this.clearOriginHolder()
      
      this.$nextTick(() => {
        let lastIndex = this.origin_filter[origin].length - 1
        if (this.origin_filter[origin][lastIndex - 1] instanceof Object || noOptionSelect) {  
          this.origin_filter[origin].splice(lastIndex - 1, 1)
        }
      })
    }
  }
}
</script>

<style lang="scss">
</style>

