export default class OriginType {
  constructor() {
    this.items = [
      {
        id: 1,
        label: "Event Sync",
        filter: "sync",
        variant: "light-success",
      },
      {
        id: 2,
        label: "Event Async",
        filter: "async",
        variant: "light-success",
      },
      {
        id: 3,
        label: "Webhook",
        filter: "webhook",
        variant: "light-success",
      },
      {
        id: 4,
        label: "Scheduler",
        filter: "scheduler",
        variant: "light-success",
      },
      {
        id: 5,
        label: "Distributor",
        filter: "distributor",
        variant: "light-success",
      },
      {
        id: 6,
        label: "Retry",
        filter: "retry",
        variant: "light-success",
      },
    ];
  }
}
