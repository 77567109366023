<template>
  <div>
    <b-skeleton-wrapper :loading="loading">
      <b-row class="d-flex justify-content-between">
        <b-col md="3" sm="4" class="mx-1 mb-50 px-0">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("common.terms.per_page") }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" class="mb-50">
          <b-form-group
            :label="$t('common.terms.filter')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('common.terms.type_to_search')"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  {{ $t("common.terms.clear") }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        responsive
        show-empty
        :fields="fields"
        :items="items"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        :empty-text="$t('common.table.no_items')"
      >

        <template #cell(identifier)="{ item }">
           <span v-for="(value, index) in item.name.split(',')" :key="value + origin">
              <p class="font-weight-bolder text-uppercase d-inline">{{ value }}</p>
              <h6 class="d-inline font-weight-bolder text-uppercase" v-if="item.name.split(',').length - 1 != index"> & </h6>
            </span>
        </template>

        <template #cell(success_percentage)="{ item }">
          <p :class="`text-${successPercentageVariantCondition(item.success_percentage)}`" class="font-weight-bolder">
            {{ item.success_percentage }}%
          </p>
        </template>

        <template #cell(elapsed_time)="{ item }">
          <p>
            {{ item.elapsed_time }}s
          </p>
        </template>

        <template #row-details="{ item }">
          <b-row>
            <b-col cols="12" md="5">
              <vue-apex-charts
                v-if="chartData[item.identifier]"
                class="bg-dark-blue pr-2 pl-1 rounded"
                type="line"
                height="75"
                width="100%"
                :options="buildRateChartOptions(chartData[item.identifier])"
                :series="buildRateChartSeries(chartData[item.identifier])"
              />
            </b-col>
            <b-col cols="10" md="5">
              <vue-apex-charts
                v-if="chartData[item.identifier]"
                class="bg-dark-blue pr-2 pl-1 rounded"
                type="bar"
                height="75"
                width="100%"
                :options="buildElapsedTimeChartOptions(chartData[item.identifier])"
                :series="buildElapsedTimeChartSeries(chartData[item.identifier])"
              />
            </b-col >
            <b-col cols="2">
              <b-button block variant="outline-favorite" class="mb-75" @click="expandAnalyticsChart(item.identifier)">
                Expand
              </b-button>
              <b-button block variant="outline-info" @click="setFilter(item.identifier)">
                Apply search
              </b-button>
            </b-col>
          </b-row>
          <b-modal
            :id="getID(item.identifier)"
            :title="$t(`dashboard.transmission.analytics.${origin}.modal.title`)"
            dialog-class="set-max-width-1500"
            size="lg"
            lazy
            centered
            ok-only
          >
            <b-container fluid>
              <b-row class="mt-50">
                <b-col>
                  <span v-for="(identifier, index) in item.name.split(',')" :key="identifier + origin">
                    <h4 class="font-weight-bolder text-light-gray text-uppercase d-inline">{{ identifier }}</h4>
                    <h5 class="d-inline font-weight-bolder text-light-gray text-uppercase" v-if="item.name.split(',').length - 1 != index"> & </h5>
                  </span>
                </b-col>
                <b-col cols="12" class="mt-1">
                  <b-row class="justify-content-center">
                    <b-col cols="8">
                      <b-progress
                        :max="item.total_hits"
                        height="13px"
                      >
                        <b-progress-bar
                          variant="success"
                          :value="accReducer(chartData[item.identifier], 'success')"
                          animated
                        />
                        <b-progress-bar
                          variant="danger"
                          :value="accReducer(chartData[item.identifier], 'error')"
                          animated
                        />
                      </b-progress>
                    </b-col>
                  </b-row>
                  <b-col cols="12" class="mt-50">
                    <h6 class="text-center text-muted font-weight-bolder text-uppercase">
                      Hits {{ accReducer(chartData[item.identifier], 'success').toLocaleString($t('locale')) }}/{{ item.total_hits.toLocaleString($t('locale')) }} success rate {{ item.success_percentage.toLocaleString($t('locale')) }}%
                    </h6>
                  </b-col>
                </b-col>
                <b-col cols="12" class="mt-1" v-if="chartData[item.identifier]">
                  <vue-apex-charts
                    :ref="getID(item.identifier + 'analytic')"
                    :key="getID(item.identifier + 'analytic')"
                    type="line"
                    height="400"
                    width="100%"
                    class="true-transition"
                    :options="buildExpandedChartOptions(chartData[item.identifier])"
                    :series="buildExpandedChartSeries(chartData[item.identifier])"
                  />
                </b-col>
                <b-col cols="12">
                  <h6 class="mb-3 text-center text-muted font-weight-bolder">From {{ formatDate(startingDate(item)) }} to {{ formatDate(endingDate(item)) }} with an interval of {{ getInterval(item) }}</h6>
                </b-col>
              </b-row>
            </b-container>
          </b-modal>
        </template>

      </b-table>
      <template #loading>
        <b-row>
          <b-col class="d-flex justify-content-between">
            <b-skeleton class="mt-1" width="320px" height="25px"/>
            <b-skeleton class="mt-1" width="320px" height="25px"/>
          </b-col>
        </b-row>
        <b-skeleton width="100%" height="35px"/>

        <b-row>
          <b-skeleton class="mt-1 ml-3" width="320px"/>
          <b-skeleton class="mt-1 ml-5" width="320px"/>
          <b-skeleton class="mt-1 ml-5" width="320px"/>
          <b-skeleton class="mt-1 ml-5" width="320px"/>
        </b-row>

        <b-row>
          <b-skeleton class="mt-1 ml-3" width="587.41px" height="90px"/>
          <b-skeleton class="mt-1 ml-2" width="587.41px" height="90px"/>
          <b-col>
            <b-skeleton class="mt-1 ml-2" width="230px" height="38px"/>
            <b-skeleton class="mt-1 ml-2" width="230px" height="38px"/>
          </b-col>
        </b-row>
      </template>
    </b-skeleton-wrapper>
    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { 
  BTable, 
  BContainer, 
  BRow, 
  BCol, 
  BButton, 
  BSkeletonWrapper, 
  BSkeleton, 
  BSkeletonTable, 
  BProgressBar, 
  BProgress, 
  BCard,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormSelect,
  BPagination,
  BFormGroup
} from 'bootstrap-vue'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
  export default {
    components: {
      BTable,
      BRow, 
      BCol,
      VueApexCharts,
      BButton,
      BSkeletonWrapper,
      BSkeleton,
      BSkeletonTable,
      BProgressBar, 
      BProgress,
      BContainer,
      BCard,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BFormSelect,
      BPagination,
      BFormGroup
    },
    props: {
      origin: {
        type: String,
        required: true,
      },  
      value: {
        type: Object,
        default: undefined
      },
      timeKeys: {
        type: Array,
        required: true
      },
    },
    data() {
      return {
        items: [],
        fields: [
          {
            key: 'identifier',
            label: "Identifier",
            sortable: true,
          },
          {
            key: "total_hits",
            label: "Total Hits",
            sortable: true,
          },
          {
            key: "success_percentage",
            label: "Success Percentage",
            sortable: true,
          },
          {
            key: "elapsed_time",
            label: "Elapsed Time",
            sortable: true,
          },
        ],

        loading: true,
        lastRowsNumber: 0,

        uuidMap: {},

        perPage: 2,
        pageOptions: [1, 2, 5, 10, 20, 40],
        totalRows: 1,
        currentPage: 1,
        filter: null,
        filterOn: [],

        schedules: null,
      }
    },
    computed: {
      chartData: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      isScheduler() {
        return this.origin == "by_scheduler"
      }
    },
    mounted() {
      this.init()
    },
    methods:{
      init() {
        if (this.isScheduler) {
          this.getSchedules()
        } else {
          this.setupChartData()
        }
      },
      setupChartData() {
        if (!this.chartData) return
        this.items = [];
        Object.keys(this.chartData).map((value) => {
          let item = this.chartData[value]
          this.items.push(
            {
              identifier: value, 
              name: this.isScheduler ? this.getIdentifier(value) : value,
              total_hits: this.accReducer(item, 'hit_counts'),
              success_percentage: ((this.accReducer(item, 'success') / Math.max(this.accReducer(item, 'success') + this.accReducer(item, 'error'), 1)) * 100).toFixed(2).toLocaleString(this.$t('locale')),
              elapsed_time: (this.accReducer(item, 'avg_elapsed_time') / (Object.keys(item.avg_elapsed_time).length * 1000 * 1000)).toFixed(5).toLocaleString(this.$t('locale')),
              _showDetails: true,
            }
          )
        })
        this.totalRows = Object.keys(this.chartData).length;
        this.loading = false
      },
      getSchedules() {
        this.$store.dispatch('getSchedules', { transmissionID: this.$route.params.transmissionID }).then((resp) => {
          this.schedules = resp
          this.$nextTick(() => {
            this.setupChartData()
          })
        })
      },
      getIdentifier(identifier) {
        let name = this.$t("dashboard.transmission.terms.deleted_scheduler");
        if (this.isScheduler) {
          this.schedules.forEach((value) => {
            if (value.identifier == identifier) {
              name = value.name;
            }
          })
        }

        return name
      },
      successPercentageVariantCondition(percentage) {
        switch(true) {
          case parseInt(percentage) < 75 : {
            return 'danger'
          }
          case parseInt(percentage) < 85 : {
            return 'warning'
          }
          case parseInt(percentage) < 95 : {
            return 'favorite'
          }
          default: {
            return 'success'
          }
        }
      },
      buildRateChartOptions(item) {
        return {
            chart: {
              toolbar: {
                show: false,
                autoSelected: 'pan',
                tools: {
                  download: false,
                  selection: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  pan: false,
                  reset: true | '<img src="/static/icons/reset.png" width="20">',
                }
              },
              fontFamily: 'Montserrat',
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              width: 2,
              show: true,
              curve: 'smooth',
            },
            legend: {
              show: false,
              position: 'top',
              horizontalAlign: 'left',
              fontSize: '14px',
              fontFamily: 'Montserrat',
              labels: {
                colors: '#fff',
              },
            },
            grid: {
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: false,
                },
              },
            },
            xaxis: {
              categories: this.timeKeys,
              labels: {
                show: false,
                style: {
                  colors: '#fff',
                  fontSize: '10px',
                  fontFamily: 'Montserrat',
                  fontWeight: 900,
                  cssClass: 'apexcharts-xaxis-label',
                },
                formatter: item ? this.formatDate : () => {}
              },
            },
            yaxis:
              {
              min: 0,
              max: this.maxElapsedTime(item.hit_counts),
              tickAmount: this.maxTickAmount(item.hit_counts),
              labels: {
                show: false,
                style: {
                  colors: '#fff',
                  fontSize: '10px',
                  fontFamily: 'Montserrat',
                  fontWeight: 900,
                  cssClass: 'apexcharts-xaxis-label',
                },
                formatter: (value) => {
                  return value.toFixed(0)
                }
              },
            },
            tooltip: {
              enabled: false,
              shared: true,
            },
            colors: ['#fff', '#ea5455', '#12e066'],
            markers: {
              size: 3,
              strokeWidth: 0,
              fillOpacity: 1,
              discrete: [],
              shape: "circle",
              radius: 2,
              showNullDataPoints: false,
            },
          }
      },
      buildRateChartSeries(item) {
        return [
          {
            name: 'Total Hits',
            data: this.timeKeys.reduce((previous, current) => {
              previous.push(item.hit_counts[current] || 0);
              return previous;
            }, [])
          },
          {
            name: 'Errors',
            data: this.timeKeys.reduce((previous, current) => {
              previous.push(item.error[current] || 0);
              return previous;
            }, [])
          },
          {
            name: 'Successes',
            data: this.timeKeys.reduce((previous, current) => {
              previous.push(item.success[current] || 0);
              return previous;
            }, [])
          },
        ]
      },
      buildElapsedTimeChartOptions(item) {
        return {
            chart: {
              toolbar: {
                show: false,
                autoSelected: 'pan',
                tools: {
                  download: false,
                  selection: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  pan: false,
                  reset: true | '<img src="/static/icons/reset.png" width="20">',
                }
              },
              fontFamily: 'Montserrat',
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              width: 0,
              show: true,
              curve: 'smooth',
            },
            legend: {
              show: false,
              position: 'top',
              horizontalAlign: 'left',
              fontSize: '14px',
              fontFamily: 'Montserrat',
              labels: {
                colors: '#fff',
              },
            },
            grid: {
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: false,
                },
              },
            },
            xaxis: {
              categories: this.timeKeys,
              labels: {
                show: false,
                style: {
                  colors: '#fff',
                  fontSize: '10px',
                  fontFamily: 'Montserrat',
                  fontWeight: 900,
                  cssClass: 'apexcharts-xaxis-label',
                },
                formatter: this.formatDate
              },
            },
            yaxis:
              {
              min: 0,
              max: this.maxElapsedTime(item.avg_elapsed_time),
              tickAmount: this.maxTickAmount(item.avg_elapsed_time),
              labels: {
                show: false,
                style: {
                  colors: '#fff',
                  fontSize: '10px',
                  fontFamily: 'Montserrat',
                  fontWeight: 900,
                  cssClass: 'apexcharts-xaxis-label',
                },
                formatter: (value) => {
                  return value.toFixed(0)
                }
              },
            },
            tooltip: {
              enabled: false,
            },
            colors: ['#00cfe880'],
            markers: {
              size: 3,
              strokeWidth: 0,
              fillOpacity: 1,
              discrete: [],
              shape: "circle",
              radius: 2,
              showNullDataPoints: false,
            },
            plotOptions: {
              bar: {
                borderRadius: 2,
                columnWidth: '40%',
              }
            }
          }
      },
      buildElapsedTimeChartSeries(item) {
        return [
          {
            name: 'Avg. Elapsed Time',
            data: this.timeKeys.reduce((previous, current) => {
              previous.push(item.avg_elapsed_time[current] || 0);
              return previous;
            }, [])
          },
        ]
      },
      buildExpandedChartOptions(item) {
        return {
          chart: {
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: false,
                reset: true,
              },
            },
            fontFamily: 'Montserrat',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [5, 5, 5, 0],
            show: true,
            curve: 'smooth',
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            labels: {
              colors: '#fff',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: item.time_keys,
            labels: {
              show: false,
              style: {
                colors: '#fff',
                fontSize: '10px',
                fontFamily: 'Montserrat',
                fontWeight: 900,
                cssClass: 'apexcharts-xaxis-label',
              },
              formatter: this.formatDate
            },
          },
          yaxis: [
            {
              title:{
                text:"Hits",
                style: {
                  fontSize:  '10px',
                  fontWeight:  'bold',
                  fontFamily:  'Montserrat',
                  color:  '#fff'
                },
              },
              min: 0,
              max: this.maxElapsedTime(item.hit_counts),
              tickAmount: this.maxTickAmount(item.hit_counts),
              labels: {
                style: {
                  colors: '#fff',
                  fontSize: '10px',
                  fontFamily: 'Montserrat',
                  fontWeight: 900,
                  cssClass: 'apexcharts-xaxis-label',
                },
                formatter: (value) => {
                  return value.toFixed(0)
                }
              },
            },
            {
              min: 0,
              max: this.maxElapsedTime(item.hit_counts),
              tickAmount: this.maxTickAmount(item.hit_counts),
              labels: {
                show: false,
                formatter: (value) => {
                  return value.toFixed(0)
                }
              },
            },
            {
              min: 0,
              max: this.maxElapsedTime(item.hit_counts),
              tickAmount: this.maxTickAmount(item.hit_counts),
              labels: {
                show: false,
                formatter: (value) => {
                  return value.toFixed(0)
                }
              },
            },
            {
              title:{
                text:"Elapsed Time",
                style: {
                  fontSize:  '10px',
                  fontWeight:  'bold',
                  fontFamily:  'Montserrat',
                  color:  '#fff'
                },
              },
              opposite: true,
              min: 0,
              max: this.maxElapsedTime(item.avg_elapsed_time),
              tickAmount: this.maxTickAmount(item.avg_elapsed_time),
              labels: {
                style: {
                  colors: '#fff',
                  fontSize: '10px',
                  fontFamily: 'Montserrat',
                  fontWeight: 900,
                  cssClass: 'apexcharts-xaxis-label',
                },
                formatter: (value) => {
                  return (Math.max(value / (1000 * 1000), 0)).toFixed(4) + 's'
                }
              },
            },

          ],
          // fill: {
          //   opacity: 1,
          //   type: 'solid',
          // },
          tooltip: {
            shared: true,
            theme: 'dark'
          },
          colors: ['#fff', '#ea5455', '#12e066', '#00cfe880'],
          markers: {
            size: 5,
            strokeWidth: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 2,
            showNullDataPoints: false,
          },
          plotOptions: {
              bar: {
                borderRadius: 5,
                columnWidth: '25%',
                colors: {
                  backgroundBarColors: [],
                  backgroundBarOpacity: 1,
                  backgroundBarRadius: 0,
                },
              }
            }
        }
      },
      buildExpandedChartSeries(item) {    
        return [
          {
            type: 'line',
            name: 'Total Hits',
            data: this.timeKeys.reduce((previous, current) => {
              previous.push(item.hit_counts[current] || 0);
              return previous;
            }, [])
          },
          {
            type: 'line',
            name: 'Errors',
            data: this.timeKeys.reduce((previous, current) => {
              previous.push(item.error[current] || 0);
              return previous;
            }, [])
          },
          {
            type: 'line',
            name: 'Successes',
            data: this.timeKeys.reduce((previous, current) => {
              previous.push(item.success[current] || 0);
              return previous;
            }, [])
          },
          {
            type: 'column',
            name: "Avg. Elapsed Time",
            data: this.timeKeys.reduce((previous, current) => {
              previous.push(item.avg_elapsed_time[current] || 0);
              return previous;
            }, [])
          },
        ]
      },
      setFilter(identifier) {
        this.$emit('searchBy', identifier);
      },
      expandAnalyticsChart(identifier) {
        this.$bvModal.show(this.getID(identifier))
        this.$nextTick(() => {
          window.dispatchEvent(new Event('resize'))
        })
        // const refs = this.$refs
        // const ref = this.getID(identifier + 'analytic')
        // console.log(refs, ref)
        // this.$refs[this.getID(identifier + 'analytic')].init()
      },
      formatDate(value) {
        if (!value) return null
        let date = new Date(value)
        return moment(date).format(this.$t('scheduler.view.moment_date_format'));
      },
      maxElapsedTime(value) {
        if (!value) return 0
        return Math.max(...Object.keys(value).reduce((previous, current) => {
          previous.push(value[current]);
          return previous;
        }, []), 1)
      },
      maxTickAmount(value) {
        if (!value) return 0
        return Math.min(Math.max(...Object.keys(value).reduce((previous, current) => {
          previous.push(value[current]);
          return previous;
        }, []), 1), 15)
      },
      accReducer(value, key) {
        if (!value) return 0

        return Object.keys(value[key]).reduce((previous, current) => {
          previous += value[key][current]
          return previous
        }, 0)
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      formatDate(value) {
        if (!value) return null
        let date = new Date(value)
        return moment(date).format(this.$t('scheduler.view.moment_date_format'));
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      startingDate(item) {
        return this.chartData[item.identifier] ? this.chartData[item.identifier].time_keys ? this.chartData[item.identifier].time_keys[0] : '' : ''
      },
      endingDate(item) {
        return this.chartData[item.identifier] ? this.chartData[item.identifier].time_keys ? this.chartData[item.identifier].time_keys.at(-1) : '' : '' 
      },
      getInterval(item) {
        return this.chartData[item.identifier] ? this.chartData[item.identifier].interval : '' 
      }
    },
  }
</script>

<style lang="scss">

.true-transition {
  transition: all 0.5s ease-in-out !important;
}
</style>

// todas as keys geral
// p/ cada gráfico x => keys
// p/ cada key => funcao getValor(success, key[0]) => return 0